<template>
    <v-container fluid  name="cambiocomponente">
      <v-row>
        <v-col cols="12" xs="12" md="3"> </v-col>
        <v-col cols="12"  xs="12" md="6" >
         <v-card height="100%" outlined>
               
            <v-img
                     src="../../../assets/aldeas-logo.png">
             </v-img> 
         
                     
            <v-form  autocomplete="off" class="ml-5 mr-5 mt-10">
  
           
            <v-list-item>
  <input style="display: none" type="text" name="fakeusernameremembered" />

              
            
              <v-text-field
              autocomplete="off"
               v-model="email"
               label="Email"
               prepend-icon="mdi-at"
              @input="limpiarResponse">
              
              </v-text-field>
            </v-list-item>
  
             </v-form>
            <v-list-item>
  
              <v-alert v-if="verError"
                dense
                outlined
                type="error"
              >
                   {{response}}
                
             </v-alert>
  
  
            </v-list-item>
            <v-card-actions>
              <v-btn
                color="primary"
                dark
                block
                :loading="loading"
                @click="getPerfil"
                class="mb-5 "
              >Iniciar Sesion</v-btn>
             
            </v-card-actions>
        
          
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" md="3" > </v-col>
  
        <!--
  
                  Aqui vienen la descripcion de API
  
        -->
      </v-row>
      <v-row>
      
      </v-row>
      <v-footer padless fixed>
        
        <v-card
          tile
          flat
          width="100%"
          color="#3A9FE0"
          dark
          height="50px"
          class="d-flex align-center justify-center"
        >
          Versión {{this.$store.state.version}}
        </v-card>
       
      </v-footer>
    </v-container>
   
  </template>
  
  <script>
  
            
  import apiUsuarios from '@/apialdeas/apiUsuarios.js';
  import controlDeSesion from '@/sesion/controlDeSesion.js';
  import variablesLocales from '@/store/variablesLocales.js';
  
  export default {
    data: function () {
      return {
        apiUsuarios,
        conectarA: null,
        show1: false,
        password: 'Password',
        email: null,
  
        pass: null,
  
        response: null,
        usuarioId: null,
        usuario: [],
        loading: false,
        imagenLogin :'',
        verError : false,
          emailRules: [ 
          v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail debe de ser valido'
        ]
      };
    },
  
    mounted: function () {
  
    //  this.crearToken();
       
    },
  
    watch: {
      loader() {
        const l = this.loader;
        this[l] = !this[l];
  
        this.loader = null;
      },
    },
  
    
  
    methods: {
  
      mostrarMenuLateral(){
  
        let usurio = this.$store.state.usuarios.usuarios_usuariologueado;
        console.log(" usurio : " + usurio.id);
        usurio.id == "" ? this.$store.dispatch('actions_uuivars_puedevermenulateral',false) : this.$store.dispatch('actions_uuivars_puedevermenulateral',true);
        
       
      
  
      },
      limpiarResponse(){
        this.response="";
        this.verError= false;
      },
      acuerdo() {
          
          this.$router.push('Acuerdo');
      },
      borrarResponse() {
        this.response = "";
      },
  
      crearCuenta() {
        this.$router.push("/usuarios/crearusuario");
      },
  
      asignarValoresDeUsuarios(response){
  
         this.$store.dispatch('action_usuarios_usuariologueado',response.data["usuario"]);
         this.$store.dispatch('action_usuarios_usuariologueado_rol',response.data["rol"]);
         this.$store.dispatch('action_usuarios_tokenUsuario', response.data["token"]);
          variablesLocales.setearToken(response.data["token"]);
          variablesLocales.setUsuarioId(response.data["usuario"]["id"]);
        //revisar la variables para mostrar el menu latreral
        //this.mostrarMenuLateral();
         //this.mandamos a mostrar el acuerdo
         this.acuerdo();
         
      },
  
      errorEnLogin() {
  
        console.log("error en login ");
  
        this.loading = false ;
        this.response ="No existe el usuario o tus credenciales son incorrenctas.";
        this.verError= true;
  
      
  
       
      } ,
  
      getPerfil () {
        
        if (this.email == "xxx"){
  
  
           this.email= "mcabrera@rebelbot.mx";
           this.pass="12345";
        }
       this.loading = true ;
       let promesa =  controlDeSesion.loginUsuario(this.email, this.pass, this.$store);
  
        promesa
       .then( response => {
         try {
          //console.log(JSON.stringify(response.data));
          console.log("mensaje : " + response.data["msg"]);
          console.log("usuario : " + response.data["usuario"]["id"]);
          console.log("roles : " + response.data["rol"]);
  
          let msg =  response.data["msg"] ;
                
          msg =="Datos del registro" ? this.asignarValoresDeUsuarios(response) :this.errorEnLogin();
          this.$store.dispatch('actions_uivars_hayUnUsuarioLogueado',true);
          
  
  
           this.loading = false ;
         }catch(error){
  
           console.log(error);
           this.errorEnLogin()
         }
          }
        )
       .catch( error => { 
         console.log(JSON.stringify(error));
  
          this.errorEnLogin() 
  
         });
  
  
      }, // termina getPerfil
      chgPass () {
        console.log("...")
        this.$router.push('Cambio');
      },
      
    },
  };
  </script>
  <style scoped>
  .background { 
    background: url('https://api.aldeasinfantiles.mx/apialdeas/ALDEASSOS.jpg') no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: auto;
  }
  </style>