<template>    
<CambioComponente></CambioComponente>  
</template>
    
<script>
import CambioComponente from '@/components/usuarios/componentes/CambioComponente.vue';
export default {

components :{ CambioComponente },
data() {
    return {
       
    }
},


}    
   
</script>
    
<style scoped>
</style>